import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'Alphabet',
    data () {
        return {
            alphabet: {}
        }
    },
    components: {
        adminLayout
    },
    mounted() {
        this.getAlphabet()
    },
    methods: {
        getAlphabet() {
            const self = this
            const sortByAsc = '?sortByAsc=albaniaAlphabet'
            const selects = '&select=id,letter,uppercase_filled_image,audio'

            httpAxios({
                url: this.$backendUrl + '/api/v1/alphabet' + sortByAsc + selects,
                method: 'GET'
            }).then(response => {
                self.alphabet = response.data.rows
            })
        }
    }
}
